<template>
    <div class="c-customers-groups">
        <v-title title="Customer Account Configurations"></v-title>

        <template v-if="selectedShop.applicationId !== '0'">
            <title-box>
                <div class="row">
                    <div class="col-9">
                        <h1>Customers Account Configurations</h1>
                    </div>
                    <div class="col-3 text-right">
                        <mercur-button :to="{ name: 'AddConfiguration' }" class="btn btn-primary text-uppercase">
                            <i class="fas fa-plus"></i> Add new account configuration
                        </mercur-button>
                    </div>
                </div>
            </title-box>
            <div class="container-fluid">
                <template>
                    <div class="row mt-3">
                        <div class="col-6">
                            <quick-search :quickSearch.sync="filters.search"/>
                        </div>
                        <div class="col-6">
                            <pagination :pagination="pagination" @change="changeAmountOfItems"/>
                        </div>
                    </div>
                    <merchant-data-table class="shadow" :options="options" :quickSearch="filters.search" :url="url"
                                         ref="table" @paginationSet="updatePagination"
                                         :isNotApplicationBound="true"></merchant-data-table>
                </template>
            </div>
        </template>

        <div v-else class="container-fluid mt-3">
            <p>Please select a shop to see configurations.</p>
        </div>

    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import QuickSearch from '@/components/elements/table/QuickSearch'

export default {
    name: 'CustomersConfigurations',
    components: { MerchantDataTable, Pagination, QuickSearch },
    data () {
        return {
            url: CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.OVERVIEW,
            pagination: {},
            amountOfDisplayedItems: 50,
            options: {
                columns: {
                    'Name': {
                        field: 'accountConfigurationName',
                        link: (value, data) => {
                            return {
                                name: 'AccountConfigurationView',
                                params: {
                                    configurationId: data.accountConfigurationId,
                                },
                            }
                        },
                        width: 150,
                    },
                    'Type': { field: 'accountConfigurationType' },
                    'Created': { field: 'dateCreated' },
                },
                quickSearchColumns: ['accountConfigurationName'],
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Delete',
                            onClick: ({ data }) => {
                                this.deleteConfiguration(data)
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
            },
            filters: {
                search: '',
            },
        }
    },
    watch: {
        selectedShop: {
            handler () {
                if (this.selectedShop.applicationId === '0') {
                    return
                }
                if (this.$refs.table) {
                    this.$refs.table.refreshGrid()
                }
            },
            immediate: true,
        },
    },
    methods: {
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
        deleteConfiguration (data) {
            this.post(CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.DELETE.replace('{accountConfigurationId}', data.accountConfigurationId))
                .then(({ data }) => {
                    this.$root.$emit('notification:global', {
                        message: 'Account configuration was deleted',
                    })

                    this.$refs.table.refreshGrid()
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.c-customers-groups {
    &__table {
        margin-top: 50px;
    }
}
</style>
